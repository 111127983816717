.richText {
  .ql-toolbar {
    border: 1px solid #636363;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .ql-container {
    border: 1px solid #636363;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #fff;
}

.ql-toolbar .ql-picker {
  stroke: white;
  border-color: #fff;
  border: 1px solid white;
}
