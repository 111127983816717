.input-placeholder::placeholder {
  font-weight: bold;
  color: #a7a7a7;
}

.input-placeholder:focus {
  outline: none;
}

.instrument_id:focus {
  outline: none;
}
