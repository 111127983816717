body {
  margin: 0;
  font-family: 'Roboto' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414 !important;
}

.tabPannel {
  flex-grow: 1 !important;
}

.main-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 4.2rem);
  overflow: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.review-bar {
  margin-bottom: 1rem;
  height: 67vh;
  overflow-y: auto;
  width: 95%;
}

.value-button {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-right: 15px;
  height: 3em;
  flex: 0;
}
.payment-value-Button {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-right: 15px;
  height: 3em;
  flex: 0;
  /* border-bottom: #fff; */
  background-color: #1e1e1e;
}
.value-button:nth-child(odd) {
  background-color: #161618;
}

.value-button:nth-child(even) {
  background-color: #1e1e1e;
}

.first-value-button {
  display: block;
  margin-left: 15px;
}
.buttons-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 1px;
  width: 400px;
  height: max-content;
}
.action-button {
  margin: 10px;
}
.review-heading-section {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.review-heading {
  color: #fff;
}
.status-chip {
  position: absolute;
  top: 0px;
  right: 50px;
  left: 50px;
}
.MuiDialog-container {
  border-radius: 15px !important;
}
.MuiDialog-paper {
  border-radius: 15px !important;
}

.highlightable::selection {
  background-color: pink;
}
.MuiInputBase-input {
  background-color: transparent !important;
}

.input-webkit-autofill {
  background-color: transparent !important;
}

.form {
  display: flex;
  flex-direction: column;
}
.form-container {
  width: 300px;
  overflow: hidden;
  transition: height 0.3s;
}
.sign-in {
  height: 400px;
}
.sign-up {
  height: 490px;
}
.forget-password {
  height: 400px;
}

.highlightable::selection {
  background-color: pink;
}

.MuiDataGrid-columnHeaderTitle {
  color: #fff !important;
}
.MuiDataGrid-footerContainer {
  height: 100px !important;
  border: none !important;
}
.first-input {
  width: 185px;
  padding: 0px;
  margin-right: 1rem;
  margin-left: 0.7rem;
  overflow: hidden;
}
.dropdown-with-error {
  width: 170px;
  padding: 0px;
  margin-right: 0.3rem;
  margin-left: 0.7rem;
  overflow: hidden;
}
.input-field-style {
  border: none;
  height: 45px;
  color: #fafafa;
  font-size: 13px;
  background-color: transparent;
  text-align: left;
  outline: none;
  padding: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 150px;
}
.input-field-style-dual-error {
  border: none;
  width: 130px;
  height: 45px;
  color: #fafafa;
  font-size: 13px;
  background-color: transparent;
  text-align: left;
  outline: none;
  padding: 0;
  padding-right: 10px;
  padding-left: 10px;
}
.input-field-style-error {
  border: none;
  width: 165px;
  height: 45px;
  color: #fafafa;
  font-size: 13px;
  background-color: transparent;
  text-align: left;
  outline: none;
  padding: 0;
  padding-right: 10px;
  padding-left: 10px;
}
.datePicker-field-style-error {
  border: none;
  width: 180px;
  height: 45px;
  color: #fafafa;
  font-size: 13px;
  background-color: transparent;
  text-align: left;
  outline: none;
  padding: 0;
}

.box-style {
  height: 40px;
  background: transparent;
  width: 200px;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #161618;
}

.paragraph-style {
  margin-top: 15px;
  margin-left: 15px;
  color: #fafafa;
  display: inline-flex;
}
.dropdown-wrapper {
  width: 185px;
  margin-top: 0.2rem;
  text-align: center;
  padding-left: 0px;
  overflow: hidden;
  margin-left: 0.4rem;
}

.docType-wrapper {
  width: 200px;
  overflow: hidden;
}
.input-field {
  width: 170px;
  border: none;
  color: #fafafa;
  background-color: transparent;
  outline: none;
  padding-right: 15px;
  display: flex;
  align-items: center;
  text-align: left;
}
.leftDrawer-image {
  height: 3rem;
}
.input-wrapper {
  display: flex;
  align-items: center;
  width: '185px';
}
.details-heading {
  margin-left: 1em;
  width: 80%;
  color: #2e97a7;
  font-size: 15px;
}

.left-area {
  display: flex;
  /* flex: 0 1 auto; */
  flex-direction: column;
  width: 450px;
  z-index: 99;
  background: #161618;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .left-area {
    display: flex;
    height: '100vh';
    overflow-y: scroll;
    flex-direction: column;
    width: 100vw;
    z-index: 100;
    background: #161618;
  }
  .buttons-bar {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1px;
    width: 80vw;
  }
  .checkbox-style {
    margin-bottom: 6rem;
  }
  .first-value-button {
    display: block;
    margin-left: 15px;

    width: 90%;
  }
  .value-button {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0% 6%;
    padding-top: 0.5em;
    height: 4em;
    border-radius: 10px;
    flex: 0;
  }
  .payment-value-Button {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0% 6%;
    padding-top: 0.5em;
    height: 4em;
    border-radius: 10px;
    flex: 0;
  }
  .value-button:nth-child(odd) {
    background-color: #161618;
  }

  .value-button:nth-child(even) {
    background-color: #1e1e1e;
  }
  .first-input {
    padding: 0 !important;
    margin-right: 1rem;
    overflow: hidden;
    margin-left: 0;
    width: 240px;
  }
  .dropdown-with-error {
    padding: 0px;
    margin-right: 0.3rem;
    margin-left: 0;
    overflow: hidden;
    width: 220px;
  }

  .box-style {
    height: 20px;
    width: 90%;
    margin-top: 0.2rem;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    width: '300px';
  }
  .input-field-style {
    height: 34px;
    width: 80%;
  }
  .input-field-style-error {
    height: 34px;
    width: 60%;
    margin-left: 0.5rem;
  }
  .datePicker-field-style-error {
    height: 34px;
    width: 60%;
    margin-left: 0.5rem;
  }
  .input-field-style-dual-error {
    height: 34px;
    width: 40%;
  }
  .dropdown-wrapper {
    height: 34px;
    margin-left: 3rem;
    width: 80%;
  }
  .input-field {
    height: 34px;
    padding-left: 2.5rem;
    width: 80%;
  }
  .review-bar {
    height: 65vh;
    width: 95%;
  }
  .loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  .docType-wrapper {
    margin-left: 3rem;
    width: 80%;
  }
}
@media screen and (max-width: 1192px) {
  .leftDrawer-image {
    height: 1.7rem !important;
  }
}
@media screen and (max-width: 850px) {
  .left-area {
    width: 380px;
  }
  .value-button {
    width: 85%;
    margin-left: 6%;
  }
  .details-heading {
    width: 85%;

    margin-left: 8%;
  }
}
@media screen and (max-width: 600px) {
  .left-area {
    width: 100%;
  }
}
.form-control {
  background-color: #141414;
  border: 1px solid #fff;
  height: 2rem;
  color: #fafafa;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10px;
}
.date-input {
  background-color: #1e1e1e;
  border: none;
  height: 2rem;
  color: #fafafa;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10px;
  width: 7vw;
  margin-top: 1rem;
  outline: none;
  font-weight: bold;
}
.react-datepicker {
  border-radius: 20px !important;
  border: none !important;
}
.react-datepicker__month-container {
  background-color: #282828;
  border-radius: 20px;
}
.react-datepicker__day {
  color: #fafafa !important;
}
.react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  background-color: #141414 !important;
}
.react-datepicker__day--disabled {
  background-color: #a7a7a719 !important;
  cursor: not-allowed !important;
  color: #606365 !important;
}
.overlayPO {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.myCustomList::-webkit-scrollbar {
  width: 0.2em;
  height: 0em;
  border-radius: 20px;
}

.myCustomList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.11);
  outline: 1px solid slategrey;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.myCustomList::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline: 1px solid slategrey;
}

ol.custom-counter {
  counter-reset: list-counter;
}

ol.custom-counter li {
  list-style: none;
  counter-increment: list-counter;
  margin-bottom: 0.5em;
}

ol.custom-counter li::before {
  content: counter(list-counter, decimal-leading-zero) '.';
  margin-right: 0.5rem;
  text-align: left;
  color: #1aacac;
}
::-ms-reveal {
  display: none;
}

.ql-container {
  background-color: #282828;
  outline: 'none';
}

::-webkit-scrollbar {
  width: 0.2em;
  height: 0em;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.11);
  outline: 1px solid slategrey;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline: 1px solid slategrey;
}

.hoverable:hover {
  color: #2e97a7;
}
.css-164027r-MuiInputBase-root-MuiInput-root:before {
  border: none !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.highlight-row {
  animation: blink 1s ease-in-out 4;
}
