.saveStateButton {
  width: 6rem;
  color: #1aacac !important;
  background-color: transparent;
  border-radius: 5px;
  text-transform: unset !important;
  height: 2.5rem;
}

.saveStateButton:disabled {
  background-color: transparent;
  color: #a7a7a7 !important;
}

.flex-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showMore_button {
  position: absolute !important;
  top: 8em;
  right: 20px;
  color: #fafafa !important;
  font-size: 10px !important;
  border-radius: 10px;
  background: #1aacac !important;
  text-transform: none !important;
}

.showMore_button:hover {
  background: #1aacac !important;
}
.paragrph_style {
  margin-left: 15px;
  color: white;
  display: inline-flex;
}

.canvas_style {
  position: absolute;
  z-index: 99;
  pointer-events: none;
  overflow: hidden !important;
}

.verify_checkbox {
  color: #1aacac !important;
  padding-left: 0px !important;
  margin-left: 0.9rem;
}

.right_section_input {
  border: none;
  font-size: 1.3rem;
  outline: none;
  width: 10rem;
  background-color: transparent;
  color: white;
}
