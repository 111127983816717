@keyframes slideRightAndZoomIn {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: scale(2.5);
  }
}

@keyframes fadeOutFromRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
