.custom-toaster {
  position: absolute;
  min-height: 3em;
  height: max-content;
  width: 34vw;
  top: 0.75em;
  right: 1%;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  z-index: 9999;
  animation: slide-in 0.4s ease forwards, slide-out 0.4s ease 5s forwards;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%); /* Start off-screen */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Final position */
  }
}

/* Keyframes for sliding out */
@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0); /* Final position */
  }
  100% {
    opacity: 0;
    transform: translateX(100%); /* Slide off-screen */
  }
}
.custom-toaster.error {
  background-color: #e9505033;
}
.custom-toaster.warning {
  background-color: #f1a23033;
}
.custom-toaster.success {
  background-color: #429eb033;
}
.custom-toaster.info {
  background-color: #24658d4d;
}

@keyframes smooth-start {
  from {
    top: -3em;
  }
  to {
    top: 0.75em;
  }
}

@keyframes smooth-animation {
  0% {
    top: -3em;
  }
  5% {
    top: 0.75em;
  }
}
@keyframes error {
  0%,
  100% {
    transform: translateX(-50%) rotate(0deg);
  }
  10%,
  50%,
  70%,
  90% {
    transform: translateX(-50%) rotate(-1deg);
  }
  20%,
  60%,
  80% {
    transform: translateX(-50%) rotate(1deg);
  }
  90% {
  }
  100% {
  }
}

.custom-toaster-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
  margin-left: 0.5rem;
  background: transparent;
}

.custom-toaster-message {
  font-size: 1em;
  color: #fff;
  font-weight: 400;
  margin-left: 1em;
}
@media screen and (max-width: 600px) {
  .custom-toaster.error {
    width: 80%;
  }
  .custom-toaster.success {
    width: 80%;
  }
  .custom-toaster-message {
    font-size: 0.75em;
  }
}
@media screen and (max-width: 900px) {
  .custom-toaster.error {
    width: 60%;
  }
  .custom-toaster.success {
    width: 60%;
  }
}
