.toasterbody {
  width: 42rem;
}

@media screen and (max-width: 670px) {
  .toasterbody {
    width: 100%;
    max-width: none;
  }
}
